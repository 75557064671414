*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'poppins', sans-serif;
}
.wrapper{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

}
.background-color{
    width: 100%;
    min-height: 100vh;
    display: flex;
}
.bg-1{
    flex: 1;
    background-color: lightgray;
}
.bg-2{
    flex: 1;
    background-color: #dc3545;
}

.about-container{
    width: 85%;
    min-height: 80vh;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: aliceblue;
    padding: 20px;
    border-radius: 5px;
}
.form-custom{
    background: white;
    display: flex;
    flex-direction: column;
    padding: 2vw 4vw;
    width: 90%;
    max-width: 600px;
    border-radius: 10px;

}
form h3{
    font-weight: 800;
    margin-bottom: 20px;
    margin-left: 20%;

}
.input-style{
    border: 0;
    margin: 10px 0;
    padding: 20px;
    outline: none;
    background: #f5f5f5;
    font-size: 16px;
}
.button-style{
    padding: 15px;
    font-size: 18px;
    border: 0;
    outline: none;
    cursor: pointer;
    width: 150px;
    margin: 20px auto 0;
    border-radius: 30px;
}